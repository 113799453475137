import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { AuthServiceProvider } from './context/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteWrapper from './AppRouteWrapper';
import Cookie from 'js-cookie';

import './App.css';
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // get the coach referral code
    const queryParams = new URLSearchParams(location.search);
    const coachReferralCode = queryParams.get('ref');
    if (coachReferralCode && coachReferralCode.length < 20) {
      Cookie.set('ref', coachReferralCode);
      navigate('/register');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthServiceProvider>
      <div className="App">
        <RouteWrapper />
      </div>
    </AuthServiceProvider>
  );
}

App.propTypes = {
  location: PropTypes.object,
};

export default App;
